import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AppSettingsModule } from '@cia-front-end-apps/shared/app-settings/app-settings.module';

const sharedImports = [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    MatListModule,
    MatTooltipModule,
    RouterModule,
    AppSettingsModule
];

@NgModule({
    exports: [
        ...sharedImports
    ],
    imports: [
        ...sharedImports
    ],
})
export class SharedAngularModule {
}
